import React from 'react';
import * as r from 'ramda';
import types from 'prop-types';
import styled from 'styled-components/macro';
import { Controller, useForm } from 'react-hook-form';
import {
  Dialog,
  Intent,
  Divider,
  Checkbox,
  FormGroup,
  InputGroup,
  HTMLSelect,
  ControlGroup,
  NumericInput,
  MultiSlider,
} from '@blueprintjs/core';

import { CardConfiguration } from 'types/dashboard';
import { DataFields, FetchIntervals } from 'constants/dashboard';

import { ColorPicker, DialogComponents } from 'components/core';

const Label = styled.div`
  flex-basis: 33.33%;
  padding-left: 2px;
  color: #5c7080;
`;

const Labels = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const CardConfigurationDialog = ({
  visible,
  icon,
  title,
  queueList,
  configuration,
  onClose,
  onSubmit,
}) => {
  const [colorRanges, setColorRanges] = React.useState([10, 30]);
  const [maxColorRangeValue, setMaxColorRangeValue] = React.useState(50);

  const { control, errors, register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      normalColor: '#ffffff',
      warningColor: '#ffb366',
      alarmColor: '#dB3737',
    },
  });

  React.useEffect(() => {
    if (visible && r.isNil(configuration) === false) {
      setTimeout(() => {
        const {
          queueId,
          queueName,
          displayName,
          dataFieldName,
          fetchInterval,
          colors,
          colorRanges,
          reverseColorRanges,
          flashOnAlarm,
          showAsGaugeChart,
        } = configuration;

        setValue('queueId', queueId);
        setValue('queueName', queueName);
        setValue('displayName', displayName);
        setValue('dataFieldName', dataFieldName);
        setValue('fetchInterval', fetchInterval);
        setValue('normalColor', colors.normal);
        setValue('warningColor', colors.warning);
        setValue('alarmColor', colors.alarm);
        setValue('flashOnAlarm', flashOnAlarm);
        setValue('showAsGaugeChart', showAsGaugeChart);

        setColorRanges(colorRanges);
        setValue('reverseColorRanges', reverseColorRanges);
      }, 0);
    }
  }, [visible, configuration, setValue, setColorRanges]);

  const handleFormSubmit = React.useCallback((data) => {
    const {
      normalColor,
      warningColor,
      alarmColor,
      fetchInterval,
      ...restData
    } = data;

    onSubmit({
      ...restData,
      fetchInterval: parseInt(fetchInterval),
      colors: {
        normal: normalColor,
        warning: warningColor,
        alarm: alarmColor,
      },
      colorRanges,
    });

    reset();
    setColorRanges([10, 30]);
  }, [colorRanges, reset, setColorRanges, onSubmit]);

  const handleColorRangeChange = React.useCallback((values) => {
    setColorRanges(values);
  }, [setColorRanges]);

  const handleMaxColorRangeValueChange = React.useCallback((value) => {
    setMaxColorRangeValue(value);

    setColorRanges(([firstRangeValue, secondRangeValue]) => {
      if (firstRangeValue < value || secondRangeValue < value) {
        return [10, 30];
      }

      if (value < 50) {
        return [Math.round(value / 3), Math.round(value / 3 * 2)];
      }

      return [firstRangeValue, secondRangeValue];
    });
  }, [setColorRanges, setMaxColorRangeValue]);

  const dialogTitle = (
    <DialogComponents.Title>{title}</DialogComponents.Title>
  );

  const dataFieldName = watch('dataFieldName');
  const colors = watch(['normalColor', 'warningColor', 'alarmColor']);
  const reverseColorRanges = watch('reverseColorRanges');

  return (
    <Dialog
      isOpen={visible}
      icon={icon}
      title={dialogTitle}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogComponents.Body>
          <FormGroup label="Queue ID">
            <HTMLSelect
              fill
              name="queueId"
              elementRef={register}
              iconProps={{ icon: 'chevron-down' }}
            >
              {queueList.map((queueId) => (
                <option key={queueId} value={queueId}>{queueId}</option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <FormGroup label="Queue name">
            <InputGroup type="text" name="queueName" inputRef={register} />
          </FormGroup>

          <FormGroup
            label="Display name"
            labelInfo="(required)"
            helperText={r.path(['displayName', 'message'], errors)}
          >
            <InputGroup
              autoFocus
              type="text"
              name="displayName"
              inputRef={register({
                required: 'Display name is required'
              })}
              intent={errors.displayName ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>

          <FormGroup label="Data field name">
            <HTMLSelect
              fill
              name="dataFieldName"
              elementRef={register}
              iconProps={{ icon: 'chevron-down' }}
            >
              {DataFields.map((dataFieldName) => (
                <option key={dataFieldName} value={dataFieldName}>{dataFieldName}</option>
              ))}
            </HTMLSelect>

            {dataFieldName === 'Abandon Rate' && (
              <Checkbox name="showAsGaugeChart" inputRef={register}>
                Show as gauge chart
              </Checkbox>
            )}
          </FormGroup>

          <FormGroup label="Fetch interval">
            <HTMLSelect
              fill
              name="fetchInterval"
              elementRef={register}
              iconProps={{ icon: 'chevron-down' }}
            >
              {r.toPairs(FetchIntervals).map(([title, value]) => (
                <option key={title} value={value}>{title}</option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <Divider />

          <FormGroup>
            <Labels>
              <Label>Normal color</Label>
              <Label>Warning color</Label>
              <Label>Alarm color</Label>
            </Labels>

            <ControlGroup fill>
              <Controller
                name="normalColor"
                as={ColorPicker}
                control={control}
              />

              <Controller
                name="warningColor"
                as={ColorPicker}
                control={control}
              />

              <Controller
                name="alarmColor"
                as={ColorPicker}
                control={control}
              />
            </ControlGroup>
          </FormGroup>

          <Checkbox name="flashOnAlarm" inputRef={register}>
            Flash on alarm
          </Checkbox>

          <Divider />

          <FormGroup style={{ marginTop: 20 }}>
            <FormGroup label="Maximum value">
              <NumericInput
                min={50}
                minorStepSize={null}
                value={maxColorRangeValue}
                clampValueOnBlur
                onValueChange={handleMaxColorRangeValueChange}
              />
            </FormGroup>

            <MultiSlider
              min={0}
              max={maxColorRangeValue}
              labelStepSize={10}
              onChange={handleColorRangeChange}
            >
              <MultiSlider.Handle
                type="full"
                value={colorRanges[0]}
                trackStyleBefore={{
                  backgroundColor: reverseColorRanges ? colors.alarmColor : colors.normalColor,
                }}
              />

              <MultiSlider.Handle
                type="full"
                value={colorRanges[1]}
                trackStyleBefore={{
                  backgroundColor: colors.warningColor,
                }}
                trackStyleAfter={{
                  backgroundColor: reverseColorRanges ? colors.normalColor : colors.alarmColor,
                }}
              />
            </MultiSlider>

            <Checkbox name="reverseColorRanges" inputRef={register}>
              Reverse color ranges
            </Checkbox>
          </FormGroup>
        </DialogComponents.Body>

        <DialogComponents.Footer>
          <DialogComponents.SubmitButton>
            Submit
          </DialogComponents.SubmitButton>

          <DialogComponents.CloseButton onClick={onClose}>
            Close
          </DialogComponents.CloseButton>
        </DialogComponents.Footer>
      </form>
    </Dialog>
  );
};

CardConfigurationDialog.propTypes = {
  visible: types.bool,
  icon: types.string,
  title: types.string.isRequired,
  queueList: types.arrayOf(types.string),
  configuration: CardConfiguration,
  onClose: types.func.isRequired,
  onSubmit: types.func.isRequired,
};

CardConfigurationDialog.defaultProps = {
  visible: false,
  queueList: [],
  configuration: null,
};

export { CardConfigurationDialog };
