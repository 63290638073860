import * as r from 'ramda';
import shortid from 'shortid';
import { put, call, select, takeEvery } from 'redux-saga/effects';

import {
  fetchConnectionResponse,
} from 'api';
import {
  GridLayoutOptions,
} from 'constants/dashboard';

import {
  addCardAction,
  addCardToSelectedPanelAction,
  updatePanelAction,
  setPanelDataAction,
  fetchPanelDataAction
} from './actions';
import {
  getSelectedPanel,
  getSelectedPanelId,
} from './selectors';
import {
  getConnectionConfiguration,
} from '../connection/selectors';

const updatePanelCardLayout = function*({ cardId, panel }) {
  const { cardLayout } = panel;

  let gridItemOptions = {};
  const isFirstPanelCard = r.isEmpty(cardLayout);
  if (isFirstPanelCard) {
    gridItemOptions = {
      i: cardId,
      x: 0,
      y: 0,
      w: GridLayoutOptions.DefaultItemWidth,
      h: GridLayoutOptions.DefaultItemHeight,
    };
  } else {
    const { x, y, w, h } = r.last(cardLayout);
    const shouldPlaceCardOnNextRow = x + w + GridLayoutOptions.DefaultItemWidth > 5;

    gridItemOptions = {
      i: cardId,
      x: shouldPlaceCardOnNextRow ? 0 : x + w,
      y: shouldPlaceCardOnNextRow ? y + h : y,
      w: GridLayoutOptions.DefaultItemWidth,
      h: GridLayoutOptions.DefaultItemHeight,
    };
  }

  const panelWithUpdatedCardLayout = {
    ...panel,
    cardLayout: [...cardLayout, gridItemOptions],
  };
  yield put(updatePanelAction(panelWithUpdatedCardLayout));
};

const addCardToSelectedPanel = function*({ payload: card }) {
  const cardId = shortid.generate();

  const cardToAdd = {
    ...card,
    id: cardId,
    panelId: yield select(getSelectedPanelId),
  }
  yield put(addCardAction(cardToAdd));

  const selectedPanel = yield select(getSelectedPanel);
  yield call(updatePanelCardLayout, {
    cardId,
    panel: selectedPanel,
  });
};

const fetchPanelData = function*({ payload }) {
  const { sourceUrl, username, password } = yield select(getConnectionConfiguration);
  const response = yield call(fetchConnectionResponse, {
    sourceUrl,
    username,
    password,
  });

  const { panelId } = payload;
  yield put(setPanelDataAction({
    panelId,
    data: response.summary.queue,
  }));
};

const dashboardSaga = function*() {
  yield takeEvery(addCardToSelectedPanelAction, addCardToSelectedPanel);
  yield takeEvery(fetchPanelDataAction, fetchPanelData);
};

export { dashboardSaga };
