import React from 'react';
import * as r from 'ramda';
import types from 'prop-types';
import styled from 'styled-components/macro';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, Intent, FormGroup, InputGroup, ControlGroup } from '@blueprintjs/core';

import { PanelConfiguration } from 'types/dashboard';

import { ColorPicker, DialogComponents } from 'components/core';

const SetDefaultBackgroundColorButton = styled(Button).attrs({
  intent: Intent.PRIMARY,
})`
  max-width: 150px;
`;

const DefaultBackgroundColor = '#eef1f3';

const PanelConfigurationDialog = ({
  visible,
  icon,
  title,
  configuration,
  onClose,
  onSubmit,
}) => {
  const { control, errors, register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      backgroundColor: DefaultBackgroundColor,
    },
  });

  React.useEffect(() => {
    if (visible && r.isNil(configuration) === false) {
      setTimeout(() => {
        const { displayName } = configuration;
        setValue('displayName', displayName);
      }, 0);
    }
  }, [visible, configuration, setValue]);

  const handleFormSubmit = React.useCallback((data) => {
    const { backgroundColor = DefaultBackgroundColor} = data;

    onSubmit({
      ...data,
      backgroundColor,
    });

    reset();
  }, [reset, onSubmit]);

  const handleSetDefaultBackgroundColorButtonClick = React.useCallback(() => {
    setValue('backgroundColor', DefaultBackgroundColor);
  }, [setValue]);

  const dialogTitle = (
    <DialogComponents.Title>{title}</DialogComponents.Title>
  );

  return (
    <Dialog
      isOpen={visible}
      icon={icon}
      title={dialogTitle}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogComponents.Body>
          <FormGroup
            label="Display name"
            labelInfo="(required)"
            helperText={r.path(['displayName', 'message'], errors)}
          >
            <InputGroup
              autoFocus
              type="text"
              name="displayName"
              inputRef={register({
                required: 'Display name is required'
              })}
              intent={errors.displayName ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>

          <FormGroup label="Background color">
            <ControlGroup fill>
              <Controller
                name="backgroundColor"
                as={ColorPicker}
                control={control}
              />

              <SetDefaultBackgroundColorButton
                onClick={handleSetDefaultBackgroundColorButtonClick}
              >
                Set default color
              </SetDefaultBackgroundColorButton>
            </ControlGroup>
          </FormGroup>
        </DialogComponents.Body>

        <DialogComponents.Footer>
          <DialogComponents.SubmitButton>
            Submit
          </DialogComponents.SubmitButton>

          <DialogComponents.CloseButton onClick={onClose}>
            Close
          </DialogComponents.CloseButton>
        </DialogComponents.Footer>
      </form>
    </Dialog>
  );
};

PanelConfigurationDialog.propTypes = {
  visible: types.bool,
  icon: types.string,
  title: types.string.isRequired,
  configuration: PanelConfiguration,
  onClose: types.func.isRequired,
  onSubmit: types.func.isRequired,
};

PanelConfigurationDialog.defaultProps = {
  visible: false,
};

export { PanelConfigurationDialog };
