import React from 'react';
import * as r from 'ramda';
import types from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { Button, Popover, Tooltip, FormGroup, InputGroup, Intent, Classes } from '@blueprintjs/core';

import { SourceUrlSuffix } from 'constants/connection';
import { ConnectionConfiguration as ConnectionConfigurationType } from 'types/connection';

import {
  getConnectionConfiguration,
  saveConnectionConfigurationAction,
} from 'store/connection';

const FormSubmitButton = styled(Button).attrs({
  type: 'submit',
  intent: Intent.PRIMARY,
})`
  min-width: 150px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SourceUrlNote = styled.p.attrs({
  className: classnames(Classes.TEXT_MUTED, Classes.TEXT_SMALL, Classes.RUNNING_TEXT),
})``;

const PopoverContent = styled.div`
  padding: 20px;
  min-width: 500px;
`;

const Container = styled.div`
  display: inline-block;
`;

const ConnectionConfigurationBase = ({
  connectionConfiguration,
  saveConnectionConfiguration,
  className,
}) => {
  const { errors, register, handleSubmit, setValue } = useForm();

  const handleFormSubmit = React.useCallback(({ sourceUrl, username, password }) => {
    saveConnectionConfiguration({ sourceUrl, username, password });
  }, [saveConnectionConfiguration]);

  const handlePopoverOpening = React.useCallback(() => {
    if (r.isNil(connectionConfiguration)) {
      return;
    }

    const { sourceUrl, username, password } = connectionConfiguration;
    setValue('sourceUrl', sourceUrl);
    setValue('username', username);
    setValue('password', password);
  }, [connectionConfiguration, setValue]);

  return (
    <Container className={className}>
      <Popover onOpening={handlePopoverOpening}>
        <Tooltip content="Connection Configuration">
          <Button minimal icon="cog" />
        </Tooltip>

        <PopoverContent>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormGroup
              label="Source URL"
              helperText={r.path(['sourceUrl', 'message'], errors)}
            >
              <InputGroup
                autoFocus
                type="text"
                name="sourceUrl"
                placeholder="http://"
                inputRef={register({
                  required: 'Source URL is required'
                })}
                intent={errors.sourceUrl ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>

            <SourceUrlNote>
              NOTE: Source URL will be appended with the next suffix<br />
              <code>{SourceUrlSuffix}</code>
            </SourceUrlNote>

            <FormGroup
              label="Username"
              helperText={r.path(['username', 'message'], errors)}
            >
              <InputGroup
                type="text"
                name="username"
                inputRef={register({
                  required: 'Username is required'
                })}
                intent={errors.username ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>

            <FormGroup
              label="Password"
              helperText={r.path(['password', 'message'], errors)}
            >
              <InputGroup
                type="password"
                name="password"
                inputRef={register({
                  required: 'Password is required'
                })}
                intent={errors.username ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>

            <ActionButtonContainer>
              <FormSubmitButton>
                Save Configuration
              </FormSubmitButton>

              <Button className={Classes.POPOVER_DISMISS}>
                Close
              </Button>
            </ActionButtonContainer>
          </form>
        </PopoverContent>
      </Popover>
    </Container>
  );
};

ConnectionConfigurationBase.propTypes = {
  connectionConfiguration: ConnectionConfigurationType,
  saveConnectionConfiguration: types.func.isRequired,
  className: types.string,
};

ConnectionConfigurationBase.defaultProps = {
  connectionConfiguration: null,
  className: '',
};

const ConnectionConfiguration = r.compose(
  connect(
    r.applySpec({
      connectionConfiguration: getConnectionConfiguration,
    }),
    {
      saveConnectionConfiguration: saveConnectionConfigurationAction,
    },
  ),
)(ConnectionConfigurationBase);

export { ConnectionConfiguration };
