import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist'
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';

import { applicationSaga } from './application';
import { connectionReducer, connectionSaga } from './connection';
import { dashboardReducer, dashboardSaga } from './dashboard';

const isDevelopmentMode = process.env.NODE_ENV === 'development';

const rootReducer = combineReducers({
  connection: connectionReducer,
  dashboard: dashboardReducer,
});

const rootSaga = function*() {
  yield all([
    applicationSaga(),
    connectionSaga(),
    dashboardSaga(),
  ]);
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, compose(
  applyMiddleware(sagaMiddleware),
  // eslint-disable-next-line no-underscore-dangle
  isDevelopmentMode && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
