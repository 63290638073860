import * as r from 'ramda';
import { createSelector } from 'reselect';

const getDashboard = r.prop('dashboard');

const getPanels = r.pipe(
  getDashboard,
  r.prop('panels'),
);

const getPanelData = r.pipe(
  getDashboard,
  r.prop('panelData'),
);

const getSelectedPanelId = r.pipe(
  getDashboard,
  r.prop('selectedPanelId'),
);

const getExpandedPanelId = r.pipe(
  getDashboard,
  r.prop('expandedPanelId'),
);

const getCards = r.pipe(
  getDashboard,
  r.prop('cards'),
);

const getSelectedPanel = createSelector(
  [getPanels, getSelectedPanelId],
  (panels, selectedPanelId) => {
    return panels.find((panel) => panel.id === selectedPanelId);
  },
);

const getSelectedPanelCards = createSelector(
  [getCards, getSelectedPanelId],
  (cards, selectedPanelId) => cards.filter(r.propEq('panelId', selectedPanelId)),
);

export {
  getPanels,
  getPanelData,
  getSelectedPanelId,
  getExpandedPanelId,
  getSelectedPanel,
  getSelectedPanelCards,
};
