import React from 'react';
import types from 'prop-types';
import styled from 'styled-components/macro';
import { Button } from '@blueprintjs/core';

const RemoveButton = styled(Button)`
  margin-left: 20px;
`;

const Container = styled.div``;

const TabTitle = ({ id, title, onRemove }) => (
  <Container>
    {title}

    {onRemove && (
      <RemoveButton
        small
        minimal
        icon="small-cross"
        onClick={(event) => {
          event.stopPropagation();
          onRemove(id);
        }}
      />
    )}
  </Container>
);

TabTitle.propTypes = {
  id: types.string.isRequired,
  title: types.string.isRequired,
  onRemove: types.func,
};

export { TabTitle };
