import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { FocusStyleManager } from "@blueprintjs/core";

import { store, persistor } from 'store';
import { Application } from './Application';

import './styles/index.scss';

FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Application />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
