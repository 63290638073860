import * as r from 'ramda';

const getConnectionBase = r.prop('connection');

const getQueueList = r.pipe(
  getConnectionBase,
  r.prop('queueList'),
);

const getConnectionConfiguration = r.pipe(
  getConnectionBase,
  r.prop('configuration'),
);

export {
  getQueueList,
  getConnectionConfiguration,
};
