import React from 'react';
import * as r from 'ramda';
import types from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import GridLayout from 'react-grid-layout';
import { Button } from '@blueprintjs/core';

import {
  CardConfiguration,
  PanelConfiguration,
} from 'types/dashboard';
import {
  GridLayoutOptions,
} from 'constants/dashboard';

import {
  getPanelData,
  getExpandedPanelId,
  getSelectedPanelCards,
  addCardToSelectedPanelAction,
  updateCardAction,
  removeCardAction,
  updatePanelAction,
  fetchPanelDataAction,
  togglePanelFullscreenModeAction,
} from 'store/dashboard';
import {
  getQueueList,
} from 'store/connection';

import {
  CardConfigurationDialog,
  PanelConfigurationDialog,
} from 'components/dashboard';
import {
  Card as CardBase,
} from './Card';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`;

const Card = styled(CardBase)`
  width: 100%;
  height: 100%;
  cursor: move;
`;

const ControlButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;

  ${props => props.isPanelExpanded && `
    bottom: 35px;
  `}
`;

const FullscreenModeButton = styled(Button).attrs({
  minimal: true,
})``;

const CardCreateButton = styled(Button).attrs({
  icon: 'new-layers',
  minimal: true,
})`
`;

const PanelConfigurationButton = styled(Button).attrs({
  icon: 'cog',
  minimal: true,
})``;

const PanelDashboardBase = ({
  queueList,
  panelData,
  panelCards,
  configuration,
  expandedPanelId,
  addCardToSelectedPanel,
  updateCard,
  removeCard,
  updatePanel,
  fetchPanelData,
  togglePanelFullscreenMode,
  className,
}) => {
  const containerRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [cardToEdit, setCardToEdit] = React.useState(null);
  const [cardConfigurationDialogVisible, setCardConfigurationDialogVisible] = React.useState(false);
  const [panelConfigurationDialogVisible, setPanelConfigurationDialogVisible] = React.useState(false);

  const { id: panelId, cardLayout } = configuration;

  const data = r.pipe(
    r.prop(panelId),
    r.defaultTo({}),
  )(panelData);

  React.useEffect(() => {
    const { offsetWidth } = containerRef.current;
    setContainerWidth(offsetWidth - 20);
  }, [containerRef, setContainerWidth]);

  React.useEffect(() => {
    if (panelCards.length === 0) {
      return;
    }

    fetchPanelData({ panelId });

    const minimalFetchInterval = r.pipe(
      r.map(r.prop('fetchInterval')),
      (fetchIntervals) => Math.min(...fetchIntervals),
    )(panelCards);

    const fetchTimer = setInterval(() => {
      fetchPanelData({ panelId });
    }, minimalFetchInterval * 1000);

    return () => {
      clearInterval(fetchTimer);
    };
  }, [panelId, panelCards, fetchPanelData]);

  const handlePanelConfigurationSubmit = React.useCallback((updatedConfiguration) => {
    updatePanel({
      id: panelId,
      ...updatedConfiguration,
    });

    setPanelConfigurationDialogVisible(false);
  }, [panelId, updatePanel, setPanelConfigurationDialogVisible]);

  const handleCardConfigurationSubmit = React.useCallback((configuration) => {
    if (cardToEdit) {
      updateCard({ id: cardToEdit.id, configuration });
      setCardToEdit(null);
    } else {
      addCardToSelectedPanel(configuration);
    }

    setCardConfigurationDialogVisible(false);
  }, [cardToEdit, addCardToSelectedPanel, updateCard, setCardToEdit, setCardConfigurationDialogVisible]);

  const handleCardEditRequest = React.useCallback((card) => () => {
    setCardToEdit(card)
    setCardConfigurationDialogVisible(true);
  }, [setCardToEdit, setCardConfigurationDialogVisible]);

  const getCardValue = (queueId, dataFieldName) => (
    r.path([queueId, dataFieldName], data)
  );

  const calculateCardValue = (queueId, dataFieldName) => {
    if (dataFieldName === 'Free agents') {
      const busy = getCardValue(queueId, 'Busy');
      const paused = getCardValue(queueId, 'Paused');
      const agents = getCardValue(queueId, 'Agents');
      return agents - (busy + paused);
    }

    return getCardValue(queueId, dataFieldName);
  };

  const handleLayoutChange = React.useCallback((cardLayout) => {
    const panelWithUpdatedLayout = {
      ...configuration,
      cardLayout,
    };
    updatePanel(panelWithUpdatedLayout);
  }, [configuration, updatePanel]);

  return (
    <Container ref={containerRef} className={className}>
      <GridLayout
        cols={GridLayoutOptions.ColumnsCount}
        rowHeight={GridLayoutOptions.RowHeight}
        width={containerWidth}
        onLayoutChange={handleLayoutChange}
      >
        {cardLayout.map(({ i: cardId, ...gridOptions }) => {
          const card = r.find(r.propEq('id', cardId))(panelCards);
          const { id, queueId, dataFieldName, showAsGaugeChart } = card;

          return (
            <div key={id} data-grid={gridOptions}>
              <Card
                configuration={card}
                value={calculateCardValue(queueId, dataFieldName)}
                showAsGaugeChart={showAsGaugeChart}
                showAsPercentage={dataFieldName === 'Abandon Rate'}
                onEdit={handleCardEditRequest(card)}
                onRemove={() => removeCard(id)}
              />
            </div>
          );
        })}
      </GridLayout>

      <ControlButtonContainer isPanelExpanded={configuration.id === expandedPanelId}>
        <FullscreenModeButton
          icon={expandedPanelId ? 'minimize' : 'maximize'}
          onClick={() => togglePanelFullscreenMode(configuration.id)}
        />

        <CardCreateButton
          onClick={() => setCardConfigurationDialogVisible(true)}
        />

        <PanelConfigurationButton
          onClick={() => setPanelConfigurationDialogVisible(true)}
        />
      </ControlButtonContainer>

      <PanelConfigurationDialog
        icon="cog"
        title="Edit panel configuration"
        configuration={configuration}
        visible={panelConfigurationDialogVisible}
        onClose={() => setPanelConfigurationDialogVisible(false)}
        onSubmit={handlePanelConfigurationSubmit}
      />

      <CardConfigurationDialog
        icon="new-layers"
        title="Add new card"
        visible={cardConfigurationDialogVisible}
        configuration={cardToEdit}
        queueList={queueList}
        onClose={() => setCardConfigurationDialogVisible(false)}
        onSubmit={handleCardConfigurationSubmit}
      />
    </Container>
  );
};

PanelDashboardBase.propTypes = {
  configuration: PanelConfiguration.isRequired,
  queueList: types.arrayOf(types.string).isRequired,
  expandedPanelId: types.string,
  panelCards: types.arrayOf(CardConfiguration).isRequired,
  addCardToSelectedPanel: types.func.isRequired,
  updateCard: types.func.isRequired,
  removeCard: types.func.isRequired,
  updatePanel: types.func.isRequired,
  fetchPanelData: types.func.isRequired,
  togglePanelFullscreenMode: types.func.isRequired,
  className: types.string,
};

const PanelDashboard = connect(
  r.applySpec({
    queueList: getQueueList,
    panelData: getPanelData,
    panelCards: getSelectedPanelCards,
    expandedPanelId: getExpandedPanelId,
  }),
  {
    addCardToSelectedPanel: addCardToSelectedPanelAction,
    updateCard: updateCardAction,
    removeCard: removeCardAction,
    updatePanel: updatePanelAction,
    fetchPanelData: fetchPanelDataAction,
    togglePanelFullscreenMode: togglePanelFullscreenModeAction,
  },
)(PanelDashboardBase);

export { PanelDashboard };
