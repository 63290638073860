import React from 'react';
import shortid from 'shortid';
import types from 'prop-types';
import styled from 'styled-components/macro';
import GaugeChartBase from 'react-gauge-chart';
import { Tag, Button } from '@blueprintjs/core';

import { CardConfiguration } from 'types/dashboard';

const QueueId = styled(Tag).attrs({
  minimal: true,
})`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white !important;
`;

const DisplayName = styled.div`
  font-size: 20px;
  text-align: center;
`;

const Value = styled.div`
  margin-top: 20px;
  font-size: 64px;
`;

const GaugeChart = styled(GaugeChartBase)`
  margin-top: 10px;

  .percent-text {
    font-family: Rubik;
  }
`;

const RemoveButton = styled(Button).attrs({
  small: true,
  minimal: true,
  icon: 'delete',
})`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ConfigurationButton = styled(Button).attrs({
  small: true,
  minimal: true,
  icon: 'cog',
})`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div.attrs(({ color }) => ({
  style: {
    backgroundColor: color,
  }
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
  padding-top: 50px;
  border-radius: 5px;
  background-color: white;
  font-family: Rubik;

  ${props => props.flash && `
    animation: blinker 2s linear infinite;
  `}

  @keyframes blinker {
    50% {
      opacity: 0.6;
    }
  }
`;

const getGaugeArcsLength = (colorRanges) => {
  const normal = colorRanges[0] / 100;
  const warning = colorRanges[1] / 100 - normal;
  const alarm = 1 - normal - warning;

  return [normal, warning, alarm];
};

const Card = ({
  value,
  configuration: {
    queueId,
    queueName,
    displayName,
    colors,
    colorRanges,
    reverseColorRanges,
    flashOnAlarm,
  },
  showAsGaugeChart,
  showAsPercentage,
  onEdit,
  onRemove,
  className,
}) => {
  const [showRemoveButton, setShowRemoveButton] = React.useState(false);
  const [showConfigurationButton, setShowConfigurationButton] = React.useState(false);

  const color = React.useMemo(() => {
    if (showAsGaugeChart) {
      return '#ffffff';
    }

    if (value <= colorRanges[0]) {
      return reverseColorRanges ? colors.alarm : colors.normal;
    }

    if (value > colorRanges[0] && value <= colorRanges[1]) {
      return colors.warning;
    }

    return reverseColorRanges ? colors.normal : colors.alarm;
  }, [value, colors, colorRanges, reverseColorRanges, showAsGaugeChart]);

  const isAlarmColor = reverseColorRanges
    ? value < colorRanges[0]
    : value > colorRanges[1];
  const shouldFlash = flashOnAlarm && isAlarmColor;

  return (
    <Container
      color={color}
      flash={shouldFlash}
      className={className}
      onMouseEnter={() => {
        setShowRemoveButton(true);
        setShowConfigurationButton(true);
      }}
      onMouseLeave={() => {
        setShowRemoveButton(false);
        setShowConfigurationButton(false);
      }}
    >
      <QueueId>
        {queueName || queueId}
      </QueueId>

      <InnerContainer>
        <DisplayName>
          {displayName}
        </DisplayName>

        {Boolean(showAsGaugeChart) === false && (
          <Value>
            {value} {showAsPercentage && '%'}
          </Value>
        )}
      </InnerContainer>

      {showAsGaugeChart && (
         <GaugeChart
          id={shortid()}
          textColor="black"
          arcsLength={getGaugeArcsLength(colorRanges)}
          colors={[colors.normal, colors.warning, colors.alarm]}
          percent={value / 100}
        />
      )}

      {showRemoveButton && (
        <RemoveButton onClick={onRemove} />
      )}

      {showConfigurationButton && (
        <ConfigurationButton onClick={onEdit} />
      )}
    </Container>
  );
};

Card.propTypes = {
  value: types.number,
  configuration: CardConfiguration.isRequired,
  showAsPercentage: types.bool,
  onEdit: types.func.isRequired,
  onRemove: types.func.isRequired,
  className: types.string,
};

Card.defaultProps = {
  value: 0,
  showAsPercentage: false,
  className: '',
};

export { Card };
