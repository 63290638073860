import React from 'react';
import * as r from 'ramda';
import types from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Tabs, Tab, Button, Alert, Intent, Classes } from '@blueprintjs/core';

import { PanelConfiguration } from 'types/dashboard';

import {
  getPanels,
  getSelectedPanelId,
  getExpandedPanelId,
  createPanelAction,
  removePanelAction,
  setSelectedPanelIdAction,
} from 'store/dashboard';

import { TabTitle } from 'components/common';
import { ClearApplicationSettings, ExportImportApplicationSettings } from 'components/application';
import { ConnectionConfiguration } from 'components/connection';
import { PanelDashboard, PanelConfigurationDialog } from 'components/dashboard';

const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  text-align: right;
`;

const TabPanel = styled(PanelDashboard)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
`;

const TabsContainer = styled.div`
  height: 100%;
  padding: 50px 20px 40px;

  .bp3-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .bp3-tab-panel {
    flex-basis: 100%;
    height: 100px;
    position: relative;
    border-radius: 5px;
    transition: all 0.2s;

    &.expanded {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
    }
  }
`;

const ActionButtons = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
`;

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f5f8fa;
`;

const ApplicationBase = ({
  panels,
  createPanel,
  removePanel,
  selectedPanelId,
  expandedPanelId,
  setSelectedPanelID,
}) => {
  const [panelToRemove, setPanelToRemove] = React.useState(null);
  const [newPanelConfigDialogVisible, setNewPanelConfigDialogVisible] = React.useState(false);

  const handleNewTabButtonClick = React.useCallback(() => {
    setNewPanelConfigDialogVisible(true);
  }, [setNewPanelConfigDialogVisible]);

  const handleNewPanelConfigSubmit = React.useCallback((panelConfiguration) => {
    createPanel(panelConfiguration)
    setNewPanelConfigDialogVisible(false);
  }, [createPanel]);

  const handlePanelChange = React.useCallback((panelId) => {
    setSelectedPanelID(panelId);
  }, [setSelectedPanelID]);

  const handleTabRemoveRequest = React.useCallback((panelId) => {
    const panelToRemove = r.find(r.propEq('id', panelId), panels);
    setPanelToRemove(panelToRemove);
  }, [panels, setPanelToRemove]);

  const handlePanelRemoveConfirm = React.useCallback(() => {
    setPanelToRemove(null);
    removePanel(panelToRemove);
  }, [panelToRemove, setPanelToRemove, removePanel]);

  return (
    <Container>
      <ActionButtons>
        <ConnectionConfiguration />
        <ClearApplicationSettings />
        <ExportImportApplicationSettings />
      </ActionButtons>

      <TabsContainer>
        <Tabs
          id="panels"
          renderActiveTabPanelOnly
          selectedTabId={selectedPanelId}
          onChange={handlePanelChange}
        >
          {panels.map((panel) => {
            const { id, displayName } = panel;

            const Title = (
              <TabTitle
                id={id}
                title={displayName}
                onRemove={handleTabRemoveRequest}
              />
            );

            const Panel = (
              <TabPanel
                configuration={panel}
                backgroundColor={panel.backgroundColor}
              />
            );

            return (
              <Tab
                id={id}
                key={id}
                title={Title}
                panel={Panel}
                panelClassName={expandedPanelId === id ? 'expanded' : ''}
              />
            );
          })}

          <Tabs.Expander />

          <Button
            minimal
            icon="add"
            text="Add new panel"
            onClick={handleNewTabButtonClick}
          />
        </Tabs>
      </TabsContainer>

      <Footer className={Classes.TEXT_MUTED}>
        © Wildix version 1.1
      </Footer>

      <PanelConfigurationDialog
        icon="add"
        title="Add new panel"
        visible={newPanelConfigDialogVisible}
        onClose={() => setNewPanelConfigDialogVisible(false)}
        onSubmit={handleNewPanelConfigSubmit}
      />

      <Alert
        icon="trash"
        cancelButtonText="Cancel"
        confirmButtonText="Yes, remove"
        intent={Intent.DANGER}
        isOpen={panelToRemove}
        onConfirm={handlePanelRemoveConfirm}
        onCancel={() => setPanelToRemove(null)}
      >
        {panelToRemove && (
          <div>
            Are you sure you want to remove panel <strong>{panelToRemove.displayName}</strong>?
          </div>
        )}
      </Alert>
    </Container>
  );
};

ApplicationBase.propTypes = {
  panels: types.arrayOf(PanelConfiguration).isRequired,
  selectedPanelId: types.string,
  expandedPanelId: types.string,
  createPanel: types.func.isRequired,
  removePanel: types.func.isRequired,
  setSelectedPanelID: types.func.isRequired,
};

const Application = r.compose(
  connect(
    r.applySpec({
      panels: getPanels,
      selectedPanelId: getSelectedPanelId,
      expandedPanelId: getExpandedPanelId,
    }),
    {
      createPanel: createPanelAction,
      removePanel: removePanelAction,
      setSelectedPanelID: setSelectedPanelIdAction,
    },
  ),
)(ApplicationBase);

export { Application };
