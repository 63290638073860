import { createActionCreator } from 'utils/store';

const createAction = createActionCreator('Connection');

const setQueueListAction = createAction('Set queue list');
const setConnectionConfigurationAction = createAction('Set configuration');
const saveConnectionConfigurationAction = createAction('Save configuration');

export {
  setQueueListAction,
  setConnectionConfigurationAction,
  saveConnectionConfigurationAction,
};
