import { createReducer } from 'redux-act';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { setQueueListAction, setConnectionConfigurationAction } from './actions';

const initialState = {
  queueList: [],
  configuration: null,
};

const reducer = {
  [setQueueListAction]: (state, queueList) => ({
    ...state,
    queueList,
  }),

  [setConnectionConfigurationAction]: (state, configuration) => ({
    ...state,
    configuration,
  }),
};

const persistConfiguration = {
  key: 'connection',
  storage,
  whitelist: ['queueList', 'configuration'],
};

const connectionReducer = persistReducer(
  persistConfiguration,
  createReducer(reducer, initialState),
);

export { connectionReducer };
