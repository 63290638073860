import axios from 'axios';

import { generateSampleResponse } from 'utils/api'
import { SourceUrlSuffix } from 'constants/connection';

const isDevelopmentMode = process.env.NODE_ENV === 'development';

const fetchConnectionResponse = async ({ sourceUrl, username, password }) => {
  if (isDevelopmentMode) {
    return generateSampleResponse();
  }

  const response = await axios.get(sourceUrl + SourceUrlSuffix, {
    auth: {
      username,
      password,
    },
  });

  return response.data;
};

export {
  fetchConnectionResponse,
};
