import React from 'react';
import styled from 'styled-components/macro';
import { ChromePicker } from 'react-color';
import { InputGroup, Popover, Position } from '@blueprintjs/core';

const ColorInput = styled(InputGroup).attrs(props => ({
  type: 'text',
  style: {
    borderRadius: 0,
    backgroundColor: props.color,
  }
}))``;

const ColorPicker = ({ value, onChange, ...props }) => {
  const handleColorPick = React.useCallback(({ hex }) => {
    onChange(hex);
  }, [onChange]);

  return (
    <Popover targetTagName="div" position={Position.BOTTOM}>
      <ColorInput color={value} value={value} readOnly {...props} />
      <ChromePicker color={value} disableAlpha onChange={handleColorPick} />
    </Popover>
  );
};

export { ColorPicker };
