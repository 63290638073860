import React from 'react';
import types from 'prop-types';
import { connect } from 'react-redux';
import { Button, Alert, Tooltip, Intent } from '@blueprintjs/core';

import { clearApplicationSettingsAction } from 'store/application';

const ClearApplicationSettingsBase = ({ clearApplicationSettings }) => {
  const [alertVisible, setAlertVisible] = React.useState(false);

  const handleConfirm = React.useCallback(() => {
    clearApplicationSettings();
    setAlertVisible(false);
  }, [setAlertVisible, clearApplicationSettings])

  return (
    <>
      <Tooltip content="Clear application settings">
        <Button minimal icon="eraser" onClick={() => setAlertVisible(true)} />
      </Tooltip>

      <Alert
        icon="trash"
        cancelButtonText="Cancel"
        confirmButtonText="Yes, remove"
        intent={Intent.DANGER}
        isOpen={alertVisible}
        onConfirm={handleConfirm}
        onCancel={() => setAlertVisible(false)}
      >
        <div>
          Are you sure you want to clear all application settings?
        </div>
      </Alert>
    </>
  );
};

ClearApplicationSettingsBase.propTypes = {
  clearApplicationSettings: types.func.isRequired,
};

const ClearApplicationSettings = connect(
  null,
  {
    clearApplicationSettings: clearApplicationSettingsAction,
  },
)(ClearApplicationSettingsBase);

export { ClearApplicationSettings };
