import styled from 'styled-components/macro';
import { Button, Classes, Intent } from '@blueprintjs/core';

const Title = styled.span`
  font-size: 14px;
`;

const Body = styled.div.attrs({
  className: Classes.DIALOG_BODY,
})``

const Footer = styled.div.attrs({
  className: Classes.DIALOG_FOOTER,
})`
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled(Button).attrs({
  type: 'submit',
  intent: Intent.PRIMARY,
})`
  min-width: 150px;
`

const CloseButton = styled(Button)`
  min-width: 150px;
`;

const DialogComponents = {
  Title,
  Body,
  Footer,
  CloseButton,
  SubmitButton,
};

export { DialogComponents };
