import React from 'react';
import types from 'prop-types';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { Button, Tooltip } from '@blueprintjs/core';

import {
  exportApplicationSettingsAction,
  importApplicationSettingsAction,
} from 'store/application';

const HiddenFileInput = styled.input.attrs({
  type: 'file',
  accept: '.json',
})`
  display: none;
  visibility: hidden;
`;

const ExportImportApplicationSettingsBase = ({
  exportApplicationSettings,
  importApplicationSettings,
}) => {
  const hiddenFileInput = React.useRef(null);

  const handleImportApplicationSettingsButtonClick = React.useCallback(() => {
    hiddenFileInput.current.click();
  }, [hiddenFileInput]);

  const handleFileSelect = React.useCallback((event) => {
    const clearFileInput = () => {
      event.target.value = '';
    };

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const settingsFileContnet = fileReader.result;
      importApplicationSettings(settingsFileContnet);
    };

    const settingsFile = event.target.files[0];
    fileReader.readAsText(settingsFile);

    clearFileInput();
  }, [importApplicationSettings]);

  return (
    <>
      <Tooltip content="Export application settings">
        <Button minimal icon="export" onClick={exportApplicationSettings} />
      </Tooltip>

      <HiddenFileInput ref={hiddenFileInput} onChange={handleFileSelect} />
      <Tooltip content="Import application settings">
        <Button minimal icon="import" onClick={handleImportApplicationSettingsButtonClick} />
      </Tooltip>
    </>
  );
};

ExportImportApplicationSettingsBase.propTypes = {
  exportApplicationSettings: types.func.isRequired,
  importApplicationSettings: types.func.isRequired,
};

const ExportImportApplicationSettings = connect(
  null,
  {
    exportApplicationSettings: exportApplicationSettingsAction,
    importApplicationSettings: importApplicationSettingsAction,
  }
)(ExportImportApplicationSettingsBase);

export { ExportImportApplicationSettings };
