const DataFields = [
  'Waiting',
  'Agents',
  'Free agents',
  'Busy',
  'Paused',
  'Answered',
  'Unanswered',
  'Abandoned',
  'Abandon Rate',
  'Avg Wait',
  'Avg Duration',
  'Max. wait time',
  'Longest waiting',
];

const FetchIntervals = {
  '5 seconds': 5,
  '10 seconds': 10,
  '20 seconds': 20,
  '30 seconds': 30,
  '40 seconds': 40,
  '50 seconds': 50,
  '1 minute': 60,
  '2 minutes': 120,
  '3 minutes': 180,
  '5 minutes': 300,
};

const GridLayoutOptions = {
  ColumnsCount: 6,
  RowHeight: 100,
  DefaultItemWidth: 2,
  DefaultItemHeight: 2,
};

export {
  DataFields,
  FetchIntervals,
  GridLayoutOptions,
};
