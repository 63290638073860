import * as r from 'ramda';
import { put, call, takeLatest } from 'redux-saga/effects';
import { Toaster, Intent } from '@blueprintjs/core';

import { fetchConnectionResponse } from 'api';

import {
  setQueueListAction,
  setConnectionConfigurationAction,
  saveConnectionConfigurationAction,
} from './actions';

const ConnectionToaster = Toaster.create();

const testConnectionConfiguration = function*({ sourceUrl, username, password }) {
  try {
    const response = yield call(fetchConnectionResponse, {
      sourceUrl,
      username,
      password,
    });

    const queueList = r.pipe(
      r.path(['summary', 'queue']),
      r.defaultTo([]),
      r.keys(),
    )(response);

    yield put(setQueueListAction(queueList));

    return true;
  } catch(error) {
    return false;
  }
};

const saveConnectionConfiguration = function*({ payload }) {
  ConnectionToaster.show({
    message: 'Testing connection configuration',
    intent: Intent.PRIMARY,
  });

  const { sourceUrl, username, password } = payload;

  const isTestSuccessful = yield call(testConnectionConfiguration, {
    sourceUrl,
    username,
    password,
  });

  if (isTestSuccessful) {
    yield put(setConnectionConfigurationAction({
      sourceUrl,
      username,
      password,
    }));

    ConnectionToaster.show({
      message: 'Connection configuration was successfully saved',
      intent: Intent.SUCCESS,
    });
  } else {
    ConnectionToaster.show({
      message: 'An error occurred during testing connection configuraiton. Check connection details',
      intent: Intent.DANGER,
    });
  }
};

const connectionSaga = function*() {
  yield takeLatest(saveConnectionConfigurationAction, saveConnectionConfiguration);
};

export { connectionSaga };
