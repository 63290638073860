import * as r from 'ramda';
import shortid from 'shortid';
import { createReducer } from 'redux-act';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  addCardAction,
  updateCardAction,
  removeCardAction,
  createPanelAction,
  updatePanelAction,
  removePanelAction,
  setPanelDataAction,
  setSelectedPanelIdAction,
  togglePanelFullscreenModeAction,
} from './actions';

const initialState = {
  panels: [],
  cards: [],
  panelData: {},
  selectedPanelId: null,
  expandedPanelId: null,
};

const reducer = {
  [addCardAction]: (state, card) => ({
    ...state,
    cards: r.append(card, state.cards),
  }),

  [updateCardAction]: (state, { id, configuration }) => {
    const index = r.findIndex(r.propEq('id', id))(state.cards);

    const { showAsGaugeChart } = configuration;
    if (r.isNil(showAsGaugeChart)) {
      configuration.showAsGaugeChart = false;
    }

    return {
      ...state,
      cards: r.adjust(index, r.mergeLeft(configuration), state.cards),
    };
  },

  [removeCardAction]: (state, cardId) => {
    const { cards, panels, selectedPanelId } = state;

    const selectedPanelIndex = r.findIndex(r.propEq('id', selectedPanelId))(panels);
    const selectedPanel = panels[selectedPanelIndex];

    const panelWithUpdatedCardLayout = {
      ...selectedPanel,
      cardLayout: r.reject(r.propEq('i', cardId))(selectedPanel.cardLayout),
    };

    return ({
      ...state,
      cards: r.reject(r.propEq('id', cardId), cards),
      panels: r.update(selectedPanelIndex, panelWithUpdatedCardLayout, panels),
    });
  },

  [createPanelAction]: (state, panel) => {
    const id = shortid.generate();

    const panelToAdd = {
      ...panel,
      id,
      cardLayout: [],
    };

    return {
      ...state,
      panels: r.append(panelToAdd, state.panels),
      selectedPanelId: id,
    };
  },

  [updatePanelAction]: (state, configuration) => {
    const index = r.findIndex(r.propEq('id', configuration.id))(state.panels);
    return {
      ...state,
      panels: r.update(index, configuration, state.panels),
    };
  },

  [removePanelAction]: (state, { id }) => ({
    ...state,
    panels: r.reject(r.propEq('id', id), state.panels),
  }),

  [setPanelDataAction]: (state, { panelId, data }) => ({
    ...state,
    panelData: {
      ...state.panelData,
      [panelId]: data,
    },
  }),

  [setSelectedPanelIdAction]: (state, panelId) => ({
    ...state,
    selectedPanelId: panelId,
  }),

  [togglePanelFullscreenModeAction]: (state, panelId) => ({
    ...state,
    expandedPanelId: state.expandedPanelId !== panelId
      ? panelId
      : null
  })
};

const persistConfiguration = {
  key: 'dashboard',
  storage,
  whitelist: ['panels', 'cards', 'selectedPanelId'],
};

export const dashboardReducer = persistReducer(
  persistConfiguration,
  createReducer(reducer, initialState),
);
