import { createActionCreator } from 'utils/store';

const createAction = createActionCreator('Application');

const clearApplicationSettingsAction = createAction('Clear settings');
const exportApplicationSettingsAction = createAction('Export settings');
const importApplicationSettingsAction = createAction('Import settings');

export {
  clearApplicationSettingsAction,
  exportApplicationSettingsAction,
  importApplicationSettingsAction,
};
