import { bool, shape, string, number, arrayOf } from 'prop-types';

const CardConfiguration = shape({
  queueId: string.isRequired,
  displayName: string.isRequired,
  dataFieldName: string.isRequired,
  fetchInterval: number.isRequired,
  colors: shape({
    normal: string.isRequired,
    warning: string.isRequired,
    alarm: string.isRequired,
  }).isRequired,
  colorRanges: arrayOf(number).isRequired,
  showAsGaugeChart: bool,
});

const PanelConfiguration = shape({
  displayName: string.isRequired,
  backgroundColor: string.isRequired,
});

export {
  CardConfiguration,
  PanelConfiguration,
};
