import { createActionCreator } from 'utils/store';

const createAction = createActionCreator('Dashboard');

const addCardAction = createAction('Add card');
const addCardToSelectedPanelAction = createAction('Add card to selected panel');
const updateCardAction = createAction('Update card');
const removeCardAction = createAction('Remove card');
const createPanelAction = createAction('Create panel');
const updatePanelAction = createAction('Update panel');
const removePanelAction = createAction('Remove panel');
const setPanelDataAction = createAction('Set panel data');
const fetchPanelDataAction = createAction('Fetch panel data');
const setSelectedPanelIdAction = createAction('Set selected panel ID');
const togglePanelFullscreenModeAction = createAction('Toggle panel fullscreen mode');

export {
  addCardAction,
  addCardToSelectedPanelAction,
  updateCardAction,
  removeCardAction,
  createPanelAction,
  updatePanelAction,
  removePanelAction,
  setPanelDataAction,
  fetchPanelDataAction,
  setSelectedPanelIdAction,
  togglePanelFullscreenModeAction,
};
