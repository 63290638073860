import { shape, string } from 'prop-types';

const ConnectionConfiguration = shape({
  sourceUrl: string.isRequired,
  username: string.isRequired,
  password: string.isRequired,
});

export {
  ConnectionConfiguration,
};
