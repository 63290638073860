import { takeEvery } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { Toaster, Intent } from '@blueprintjs/core';

import {
  clearApplicationSettingsAction,
  exportApplicationSettingsAction,
  importApplicationSettingsAction,
} from './actions';

const ApplicationToaster = Toaster.create();

const clearApplicationSettings = () => {
  window.localStorage.clear();
  window.location.reload();
};

const exportApplicationSettings = () => {
  try {
    const connectionSettings = localStorage.getItem('persist:connection');
    const dashboardSettings = localStorage.getItem('persist:dashboard');

    const settings = {
      connection: connectionSettings,
      dashboard: dashboardSettings,
    };

    const blob = new Blob([JSON.stringify(settings)], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'settings.json');
  } catch {
    ApplicationToaster.show({
      message: 'Something went wrong while saving application settings. Try to refresh the page and export again',
      intent: Intent.DANGER,
    });
  }
};

const importApplicationSettinsg = ({ payload: settingsContent }) => {
  try {
    const settings = JSON.parse(settingsContent);
    const { connection, dashboard } = settings;

    localStorage.setItem('persist:connection', connection);
    localStorage.setItem('persist:dashboard', dashboard);
    window.location.reload();
  } catch {
    ApplicationToaster.show({
      message: 'An error occured when importing application settings',
      intent: Intent.DANGER,
    });
  }
};

const applicationSaga = function*() {
  yield takeEvery(clearApplicationSettingsAction, clearApplicationSettings);
  yield takeEvery(exportApplicationSettingsAction, exportApplicationSettings);
  yield takeEvery(importApplicationSettingsAction, importApplicationSettinsg);
};

export { applicationSaga };
